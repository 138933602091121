
import {defineComponent} from "vue";
import {Empty} from 'ant-design-vue';

export default defineComponent({
  name: "EmptyNoLesson",
  components: {
    AEmpty: Empty,
  },
  props:{
    imgPath: {
      type: String,
      required:false,
      default: require('@/assets/image/timetable/timetable-nolesson.png'),
    },
    height: {
      type: [Number, String],
      required: false,
      default: 157
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
